import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Skills from './components/Skills';
import About from './components/About';
import ExperienceTimeline from './components/ExperienceTimeline';
import Contact from './components/Contact';

import Hero from './components/Hero';
import Navbar from './components/Navbar';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Navbar/>
    <Hero />    
    <About />
    <Skills />
    <ExperienceTimeline />
    <Contact />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
